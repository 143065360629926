var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Approving Universal Courier Accounts"}},[_c('b-modal',{attrs:{"id":"modal-1","title":"Confirmation","ok-only":"","ok-title":"Yes","hide-footer":_vm.alertMessage == 1},on:{"ok":_vm.activateSeller}},[_c('b-card-text',[(_vm.alertMessage == 0)?_c('span',[_vm._v(" Are you sure you want to process further? ")]):(_vm.alertMessage == 1)?_c('span',[(_vm.returnMessageStatus == 0)?_c('span',[_c('b-alert',{attrs:{"variant":"success","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.returnMessage))])])])],1):(_vm.returnMessageStatus == 1)?_c('span',[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.returnMessage))])])])],1):_vm._e()]):_vm._e()])],1),_c('vue-good-table',{ref:"unverified-sellers",attrs:{"mode":"local","columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"isLoading":_vm.isLoadingProp,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm
        },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        },"totalRows":_vm.totalRecords,"pagination-options":{
          enabled: true,
        }},on:{"on-column-filter":_vm.onColumnFilter,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setRow(props.row)}}},[_vm._v(" Approve ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":_vm.pages},on:{"input":_vm.handlePageChange},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('b-spinner',{ref:"spinner_one",staticClass:"mr-1",staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary","type":"grow"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }